<template>
  <nav
    class="
      navbar navbar-main navbar-expand-lg
      px-0
      mx-4
      shadow-none
      border-radius-xl
    "
    id="navbarBlur"
    data-scroll="false"
  >
    <div class="container-fluid py-1 px-3">
      <nav aria-label="breadcrumb">
        <h4 class="font-weight-bolder text-white mb-0">SITA FH-UNPAS</h4>
      </nav>
      <div
        class="collapse navbar-collapse mt-sm-0 mt-2 me-md-0 me-sm-4"
        id="navbar"
      >
        <div class="ms-md-auto pe-md-3 d-flex align-items-center"></div>
        <ul class="navbar-nav justify-content-end">
          <li class="nav-item d-flex align-items-center">
            <a
              href="javascript:;"
              class="nav-link text-white font-weight-bold px-0"
            >
              <i class="fa fa-user me-sm-1"></i>
              <span class="d-sm-inline d-none">{{ user["nama"] }}</span>
            </a>
          </li>
          <li class="nav-item d-xl-none ps-3 d-flex align-items-center">
            <a
              href="javascript:;"
              class="nav-link text-white p-0"
              id="iconNavbarSidenav"
              v-on:click="showSideBar()"
            >
              <div class="sidenav-toggler-inner">
                <i class="sidenav-toggler-line bg-white"></i>
                <i class="sidenav-toggler-line bg-white"></i>
                <i class="sidenav-toggler-line bg-white"></i>
              </div>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters({
      isLoggedIn: "isLoggedIn",
      user: "user",
      role: "role",
      aktivasi: "aktivasi",
    }),
  },
  data() {
    return {
      isActive: false,
    };
  },
  methods: {
    showSideBar() {
      if (this.isActive == true) {
        document.body.classList.remove("g-sidenav-pinned");
        this.isActive = false;
      } else {
        document.body.classList.add("g-sidenav-pinned");
        this.isActive = true;
      }
    },
  },
};
</script>

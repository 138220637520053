<template>
  <aside
    class="sidenav bg-white navbar navbar-vertical navbar-expand-xs border-0 border-radius-xl my-3 fixed-start ms-4"
    style="z-index: 3"
    id="sidenav-main"
  >
    <div class="sidenav-header">
      <i
        class="fas fa-times p-3 cursor-pointer text-secondary opacity-5 position-absolute end-0 top-0 d-none d-xl-none"
        aria-hidden="true"
        id="iconSidenav"
      ></i>
      <a class="navbar-brand m-0" href="" target="_blank">
        <img
          src="/assets/img/logo-ct-dark.png"
          class="navbar-brand-img h-100 me-2"
          alt="main_logo"
        />
        <span class="ms-1 font-weight-bold">SITA FH-UNPAS</span>
      </a>
    </div>
    <hr class="horizontal dark mt-0" />
    <div class="collapse navbar-collapse w-auto" id="sidenav-collapse-main">
      <ul class="navbar-nav">
        <template v-if="role == 'mahasiswa'">
          <template v-if="topik == 'Artikel Ilmiah Nasional/Internasional'">
            <li class="nav-item">
              <router-link class="nav-link" to="/profile">
                <div
                  class="icon icon-shape icon-sm border-radius-md text-center me-2 d-flex align-items-center justify-content-center"
                >
                  <i
                    class="ni ni-single-02 text-primary text-sm opacity-10"
                  ></i>
                </div>
                <span class="nav-link-text ms-1">Profile</span>
              </router-link>
            </li>
            <li class="nav-item">
              <router-link class="nav-link" to="/pengajuan-sidang-artikel">
                <div
                  class="icon icon-shape icon-sm border-radius-md text-center me-2 d-flex align-items-center justify-content-center"
                >
                  <i class="ni ni-hat-3 text-success text-sm opacity-10"></i>
                </div>
                <span class="nav-link-text ms-1">Sidang Kompre</span>
              </router-link>
            </li>
            <li class="nav-item">
              <router-link class="nav-link" to="/ijazah">
                <div
                  class="icon icon-shape icon-sm border-radius-md text-center me-2 d-flex align-items-center justify-content-center"
                >
                  <i
                    class="ni ni-paper-diploma text-dark text-sm opacity-10"
                  ></i>
                </div>
                <span class="nav-link-text ms-1">Ijazah</span>
              </router-link>
            </li>
          </template>
          <template v-else-if="topik == 'Presentasi Nasional/Internasional'">
            <li class="nav-item">
              <router-link class="nav-link" to="/profile">
                <div
                  class="icon icon-shape icon-sm border-radius-md text-center me-2 d-flex align-items-center justify-content-center"
                >
                  <i
                    class="ni ni-single-02 text-primary text-sm opacity-10"
                  ></i>
                </div>
                <span class="nav-link-text ms-1">Profile</span>
              </router-link>
            </li>
            <li class="nav-item">
              <router-link class="nav-link" to="/pengajuan-sidang-presentasi">
                <div
                  class="icon icon-shape icon-sm border-radius-md text-center me-2 d-flex align-items-center justify-content-center"
                >
                  <i class="ni ni-hat-3 text-success text-sm opacity-10"></i>
                </div>
                <span class="nav-link-text ms-1">Sidang Kompre</span>
              </router-link>
            </li>
            <li class="nav-item">
              <router-link class="nav-link" to="/ijazah">
                <div
                  class="icon icon-shape icon-sm border-radius-md text-center me-2 d-flex align-items-center justify-content-center"
                >
                  <i
                    class="ni ni-paper-diploma text-dark text-sm opacity-10"
                  ></i>
                </div>
                <span class="nav-link-text ms-1">Ijazah</span>
              </router-link>
            </li>
          </template>
          <template v-else>
            <li class="nav-item">
              <router-link class="nav-link" to="/profile">
                <div
                  class="icon icon-shape icon-sm border-radius-md text-center me-2 d-flex align-items-center justify-content-center"
                >
                  <i
                    class="ni ni-single-02 text-primary text-sm opacity-10"
                  ></i>
                </div>
                <span class="nav-link-text ms-1">Profile</span>
              </router-link>
            </li>
            <li class="nav-item">
              <router-link class="nav-link" to="/pengajuan-seminar">
                <div
                  class="icon icon-shape icon-sm border-radius-md text-center me-2 d-flex align-items-center justify-content-center"
                >
                  <i class="ni ni-laptop text-danger text-sm opacity-10"></i>
                </div>
                <span class="nav-link-text ms-1">Seminar UP</span>
              </router-link>
            </li>
            <li class="nav-item">
              <router-link class="nav-link" to="/pengajuan-sidang">
                <div
                  class="icon icon-shape icon-sm border-radius-md text-center me-2 d-flex align-items-center justify-content-center"
                >
                  <i class="ni ni-hat-3 text-success text-sm opacity-10"></i>
                </div>
                <span class="nav-link-text ms-1">Sidang Kompre</span>
              </router-link>
            </li>
            <li class="nav-item">
              <router-link class="nav-link" to="/ijazah">
                <div
                  class="icon icon-shape icon-sm border-radius-md text-center me-2 d-flex align-items-center justify-content-center"
                >
                  <i
                    class="ni ni-paper-diploma text-dark text-sm opacity-10"
                  ></i>
                </div>
                <span class="nav-link-text ms-1">Ijazah</span>
              </router-link>
            </li>
          </template>
        </template>
        <template v-else-if="role == 'akademik1'">
          <li class="nav-item">
            <h6
              class="ps-4 ms-2 text-uppercase text-xs font-weight-bolder opacity-6"
            >
              Menu
            </h6>
          </li>
          <li class="nav-item">
            <a
              data-bs-toggle="collapse"
              id="collapsed"
              href="javascript:;"
              class="nav-link"
              role="button"
              v-on:click="showDropdownAkademikPengajuanPH()"
            >
              <div
                class="icon icon-shape icon-sm text-center d-flex align-items-center justify-content-center"
              >
                <i class="ni ni-single-02 text-info text-sm opacity-10"></i>
              </div>
              <span class="nav-link-text ms-1">Pengajuan PH</span>
            </a>
            <div class="collapse" id="applicationsExamples">
              <ul class="nav ms-4" id="list">
                <li class="nav-item">
                  <router-link
                    class="nav-link"
                    to="/akademik/pengajuan-ph/cek-transkrip"
                  >
                    <span class="nav-link-text ms-1">Transkrip</span>
                  </router-link>
                </li>
              </ul>
            </div>
          </li>
          <li class="nav-item">
            <a
              data-bs-toggle="collapse"
              id="collapsed"
              href="javascript:;"
              class="nav-link"
              role="button"
              v-on:click="showDropdownAkademikSeminarUP()"
            >
              <div
                class="icon icon-shape icon-sm text-center d-flex align-items-center justify-content-center"
              >
                <i class="ni ni-tv-2 text-primary text-sm opacity-10"></i>
              </div>
              <span class="nav-link-text ms-1">Seminar UP</span>
            </a>
            <div class="collapse" id="seminarUp">
              <ul class="nav ms-4" id="list">
                <li class="nav-item">
                  <router-link
                    class="nav-link"
                    to="/akademik/pengajuan-seminar/cek-transkrip-up"
                  >
                    <span class="nav-link-text ms-1">Transkrip UP</span>
                  </router-link>
                </li>
              </ul>
            </div>
          </li>
          <li class="nav-item">
            <a
              data-bs-toggle="collapse"
              id="collapsed"
              href="javascript:;"
              class="nav-link"
              role="button"
              v-on:click="showDropdownAkademikSidangKompre()"
            >
              <div
                class="icon icon-shape icon-sm text-center d-flex align-items-center justify-content-center"
              >
                <i
                  class="ni ni-single-copy-04 text-warning text-sm opacity-10"
                ></i>
              </div>
              <span class="nav-link-text ms-1">Sidang Kompre</span>
            </a>
            <div class="collapse" id="sidangKompre">
              <ul class="nav ms-4" id="list">
                <li class="nav-item">
                  <router-link
                    class="nav-link"
                    to="/akademik/pengajuan-sidang/cek-transkrip-sidang"
                  >
                    <span class="nav-link-text ms-1">Transkrip Sidang</span>
                  </router-link>
                </li>

                <li class="nav-item">
                  <router-link
                    class="nav-link"
                    to="/akademik/pengajuan-sidang/cek-slide-sidang"
                  >
                    <span class="nav-link-text ms-1">Slide Pemaparan</span>
                  </router-link>
                </li>
                <li class="nav-item">
                  <router-link
                    class="nav-link"
                    to="/akademik/pengajuan-sidang/cek-ktp"
                  >
                    <span class="nav-link-text ms-1">Foto KTP</span>
                  </router-link>
                </li>
                <li class="nav-item">
                  <router-link
                    class="nav-link"
                    to="/akademik/pengajuan-sidang/cek-pas-foto"
                  >
                    <span class="nav-link-text ms-1">Pas Foto</span>
                  </router-link>
                </li>
                <li class="nav-item">
                  <router-link
                    class="nav-link"
                    to="/akademik/pengajuan-sidang/penyerahan-berkas"
                  >
                    <span class="nav-link-text ms-1">Berkas Sidang</span>
                  </router-link>
                </li>
              </ul>
            </div>
          </li>
          <li class="nav-item">
            <a
              data-bs-toggle="collapse"
              id="collapsed"
              href="javascript:;"
              class="nav-link"
              role="button"
              v-on:click="showDropdownAkademikPelasanaanSidang()"
            >
              <div
                class="icon icon-shape icon-sm text-center d-flex align-items-center justify-content-center"
              >
                <i class="ni ni-hat-3 text-success text-sm opacity-10"></i>
              </div>
              <span class="nav-link-text ms-1">Pelaksanaan Sidang</span>
            </a>
            <div class="collapse" id="pelaksanaanSidang">
              <ul class="nav ms-4" id="list">
                <li class="nav-item">
                  <router-link
                    class="nav-link"
                    to="/akademik/pengajuan-sidang/proses-sidang"
                  >
                    <span class="nav-link-text ms-1">Proses Sidang</span>
                  </router-link>
                </li>
              </ul>
            </div>
          </li>
          <li class="nav-item">
            <a
              data-bs-toggle="collapse"
              id="collapsed"
              href="javascript:;"
              class="nav-link"
              role="button"
              v-on:click="showDropdownAkademikIjazah()"
            >
              <div
                class="icon icon-shape icon-sm text-center d-flex align-items-center justify-content-center"
              >
                <i class="ni ni-paper-diploma text-dark text-sm opacity-10"></i>
              </div>
              <span class="nav-link-text ms-1">Ijazah</span>
            </a>
            <div class="collapse" id="pengurusanIjazah">
              <ul class="nav ms-4" id="list">
                <li class="nav-item">
                  <router-link
                    class="nav-link"
                    to="/akademik/ijazah/penyerahan-foto"
                  >
                    <span class="nav-link-text ms-1">Penyerahan Foto</span>
                  </router-link>
                </li>
                <li class="nav-item">
                  <router-link
                    class="nav-link"
                    to="/akademik/ijazah/pembuatan-ijazah"
                  >
                    <span class="nav-link-text ms-1">Pembuatan Ijazah</span>
                  </router-link>
                </li>
              </ul>
            </div>
          </li>
        </template>
        <template v-else-if="role == 'keuangan1'">
          <li class="nav-item">
            <h6
              class="ps-4 ms-2 text-uppercase text-xs font-weight-bolder opacity-6"
            >
              Menu
            </h6>
          </li>
          <li class="nav-item">
            <a
              data-bs-toggle="collapse"
              id="collapsed"
              href="javascript:;"
              class="nav-link"
              role="button"
              v-on:click="showDropdownKeuanganPembayaran()"
            >
              <div
                class="icon icon-shape icon-sm text-center d-flex align-items-center justify-content-center"
              >
                <i
                  class="ni ni-money-coins text-primary text-sm opacity-10"
                ></i>
              </div>
              <span class="nav-link-text ms-1">Pembayaran</span>
            </a>
            <div class="collapse" id="pengajuanKeuanganPembayaran">
              <ul class="nav ms-4" id="list">
                <li class="nav-item">
                  <router-link
                    class="nav-link"
                    to="/keuangan/pengajuan-ph/pembayaran"
                  >
                    <span class="nav-link-text ms-1">Pembayaran</span>
                  </router-link>
                </li>
              </ul>
            </div>
          </li>
          <li class="nav-item">
            <a
              data-bs-toggle="collapse"
              id="collapsed"
              href="javascript:;"
              class="nav-link"
              role="button"
              v-on:click="showDropdownKeuanganPengajuanPH()"
            >
              <div
                class="icon icon-shape icon-sm text-center d-flex align-items-center justify-content-center"
              >
                <i class="ni ni-single-02 text-info text-sm opacity-10"></i>
              </div>
              <span class="nav-link-text ms-1">Pengajuan PH</span>
            </a>
            <div class="collapse" id="pengajuanKeuangan">
              <ul class="nav ms-4" id="list">
                <li class="nav-item">
                  <router-link
                    class="nav-link"
                    to="/keuangan/pengajuan-ph/cek-dpp"
                  >
                    <span class="nav-link-text ms-1">Pengecekan DPP</span>
                  </router-link>
                </li>
                <li class="nav-item">
                  <router-link
                    class="nav-link"
                    to="/keuangan/pengajuan-ph/cek-dpps"
                  >
                    <span class="nav-link-text ms-1">Pengecekan DPPS</span>
                  </router-link>
                </li>
              </ul>
            </div>
          </li>
          <li class="nav-item">
            <a
              data-bs-toggle="collapse"
              id="collapsed"
              href="javascript:;"
              class="nav-link"
              role="button"
              v-on:click="showDropdownKeuanganUP()"
            >
              <div
                class="icon icon-shape icon-sm text-center d-flex align-items-center justify-content-center"
              >
                <i class="ni ni-laptop text-danger text-sm opacity-10"></i>
              </div>
              <span class="nav-link-text ms-1">Seminar UP</span>
            </a>
            <div class="collapse" id="pengajuanKeuanganUP">
              <ul class="nav ms-4" id="list">
                <li class="nav-item">
                  <router-link
                    class="nav-link"
                    to="/keuangan/pengajuan-seminar/cek-dpp-up"
                  >
                    <span class="nav-link-text ms-1">Cek DPP UP</span>
                  </router-link>
                </li>
                <li class="nav-item">
                  <router-link
                    class="nav-link"
                    to="/keuangan/pengajuan-seminar/cek-dpps-up"
                  >
                    <span class="nav-link-text ms-1">Cek DPPS UP</span>
                  </router-link>
                </li>
              </ul>
            </div>
          </li>
          <li class="nav-item">
            <a
              data-bs-toggle="collapse"
              id="collapsed"
              href="javascript:;"
              class="nav-link"
              role="button"
              v-on:click="showDropdownKeuanganKompre()"
            >
              <div
                class="icon icon-shape icon-sm text-center d-flex align-items-center justify-content-center"
              >
                <i class="ni ni-hat-3 text-success text-sm opacity-10"></i>
              </div>
              <span class="nav-link-text ms-1">Sidang Kompre</span>
            </a>
            <div class="collapse" id="pengajuanKeuanganKompre">
              <ul class="nav ms-4" id="list">
                <li class="nav-item">
                  <router-link
                    class="nav-link"
                    to="/keuangan/pengajuan-sidang/cek-dpp-sidang"
                  >
                    <span class="nav-link-text ms-1">Cek DPP Sidang</span>
                  </router-link>
                </li>
                <li class="nav-item">
                  <router-link
                    class="nav-link"
                    to="/keuangan/pengajuan-sidang/cek-dpps-sidang"
                  >
                    <span class="nav-link-text ms-1">Cek DPPS Sidang</span>
                  </router-link>
                </li>
                <li class="nav-item">
                  <router-link
                    class="nav-link"
                    to="/keuangan/pengajuan-sidang/cek-dispensasi-dpp-sidang"
                  >
                    <span class="nav-link-text ms-1">Dispen DPP Sidang</span>
                  </router-link>
                </li>
                <li class="nav-item">
                  <router-link
                    class="nav-link"
                    to="/keuangan/pengajuan-sidang/cek-dispensasi-dpps-sidang"
                  >
                    <span class="nav-link-text ms-1">Dispen DPPS Sidang</span>
                  </router-link>
                </li>
              </ul>
            </div>
          </li>
        </template>
        <template v-else-if="role == 'staffwd1'">
          <li class="nav-item">
            <h6
              class="ps-4 ms-2 text-uppercase text-xs font-weight-bolder opacity-6"
            >
              Pengajuan PH
            </h6>
          </li>
          <li class="nav-item">
            <router-link
              class="nav-link"
              to="/staff-wakil-dekan/pengajuan-ph/cek-pengajuan"
            >
              <div
                class="icon icon-shape icon-sm border-radius-md text-center me-2 d-flex align-items-center justify-content-center"
              >
                <i class="ni ni-single-02 text-primary text-sm opacity-10"></i>
              </div>
              <span class="nav-link-text ms-1">Pengajuan Judul</span>
            </router-link>
          </li>
          <li class="nav-item">
            <router-link
              class="nav-link"
              to="/staff-wakil-dekan/pengajuan-ph/alokasi-pembimbing"
            >
              <div
                class="icon icon-shape icon-sm border-radius-md text-center me-2 d-flex align-items-center justify-content-center"
              >
                <i class="ni ni-single-02 text-success text-sm opacity-10"></i>
              </div>
              <span class="nav-link-text ms-1">Alokasi Pembimbing</span>
            </router-link>
          </li>
          <li class="nav-item mt-4">
            <h6
              class="ps-4 ms-2 text-uppercase text-xs font-weight-bolder opacity-6"
            >
              Pengajuan Sidang
            </h6>
          </li>
          <li class="nav-item">
            <router-link
              class="nav-link"
              to="/staff-wakil-dekan/pengajuan-sidang/cek-lembar-dosposisi"
            >
              <div
                class="icon icon-shape icon-sm border-radius-md text-center me-2 d-flex align-items-center justify-content-center"
              >
                <i
                  class="ni ni-single-copy-04 text-danger text-sm opacity-10"
                ></i>
              </div>
              <span class="nav-link-text ms-1">Lembar Disposisi</span>
            </router-link>
          </li>
          <li class="nav-item mt-4">
            <h6
              class="ps-4 ms-2 text-uppercase text-xs font-weight-bolder opacity-6"
            >
              Alokasi Penguji
            </h6>
          </li>
          <li class="nav-item">
            <router-link
              class="nav-link"
              to="/staff-wakil-dekan/pengajuan-sidang/alokasi-penguji"
            >
              <div
                class="icon icon-shape icon-sm border-radius-md text-center me-2 d-flex align-items-center justify-content-center"
              >
                <i class="ni ni-single-02 text-warning text-sm opacity-10"></i>
              </div>
              <span class="nav-link-text ms-1">Alokasi Penguji</span>
            </router-link>
          </li>
        </template>
        <template v-else-if="role == 'wd1'">
          <li class="nav-item">
            <h6
              class="ps-4 ms-2 text-uppercase text-xs font-weight-bolder opacity-6"
            >
              Pengajuan PH
            </h6>
          </li>
          <li class="nav-item">
            <router-link
              class="nav-link"
              to="/wakil-dekan/pengajuan-ph/cek-pengajuan"
            >
              <div
                class="icon icon-shape icon-sm border-radius-md text-center me-2 d-flex align-items-center justify-content-center"
              >
                <i class="ni ni-single-02 text-primary text-sm opacity-10"></i>
              </div>
              <span class="nav-link-text ms-1">Pengajuan Judul</span>
            </router-link>
          </li>
          <li class="nav-item mt-4">
            <h6
              class="ps-4 ms-2 text-uppercase text-xs font-weight-bolder opacity-6"
            >
              Sidang Komprehensif
            </h6>
          </li>
          <li class="nav-item">
            <router-link
              class="nav-link"
              to="/wakil-dekan/pengajuan-sidang/cek-laporan-sidang"
            >
              <div
                class="icon icon-shape icon-sm border-radius-md text-center me-2 d-flex align-items-center justify-content-center"
              >
                <i
                  class="ni ni-single-copy-04 text-danger text-sm opacity-10"
                ></i>
              </div>
              <span class="nav-link-text ms-1">Dokumen Laporan</span>
            </router-link>
          </li>
          <li class="nav-item">
            <router-link
              class="nav-link"
              to="/wakil-dekan/pengajuan-sidang/cek-korespondensi"
            >
              <div
                class="icon icon-shape icon-sm border-radius-md text-center me-2 d-flex align-items-center justify-content-center"
              >
                <i
                  class="ni ni-single-copy-04 text-primary text-sm opacity-10"
                ></i>
              </div>
              <span class="nav-link-text ms-1">Dokumen Korespondensi</span>
            </router-link>
          </li>
          <li class="nav-item">
            <router-link
              class="nav-link"
              to="/wakil-dekan/pengajuan-sidang/cek-loa"
            >
              <div
                class="icon icon-shape icon-sm border-radius-md text-center me-2 d-flex align-items-center justify-content-center"
              >
                <i
                  class="ni ni-single-copy-04 text-info text-sm opacity-10"
                ></i>
              </div>
              <span class="nav-link-text ms-1">Letter Of Acceptance</span>
            </router-link>
          </li>
          <li class="nav-item">
            <router-link
              class="nav-link"
              to="/wakil-dekan/pengajuan-sidang/cek-link-jurnal"
            >
              <div
                class="icon icon-shape icon-sm border-radius-md text-center me-2 d-flex align-items-center justify-content-center"
              >
                <i class="ni ni-compass-04 text-primary text-sm opacity-10"></i>
              </div>
              <span class="nav-link-text ms-1">Link Jurnal</span>
            </router-link>
          </li>
          <li class="nav-item mt-4">
            <h6
              class="ps-4 ms-2 text-uppercase text-xs font-weight-bolder opacity-6"
            >
              Alokasi Penguji
            </h6>
          </li>
          <li class="nav-item">
            <router-link
              class="nav-link"
              to="/wakil-dekan/pengajuan-sidang/alokasi-penguji"
            >
              <div
                class="icon icon-shape icon-sm border-radius-md text-center me-2 d-flex align-items-center justify-content-center"
              >
                <i class="ni ni-single-02 text-primary text-sm opacity-10"></i>
              </div>
              <span class="nav-link-text ms-1">Alokasi Penguji</span>
            </router-link>
          </li>
        </template>
        <template v-else-if="role == 'dosen'">
          <li class="nav-item">
            <h6
              class="ps-4 ms-2 text-uppercase text-xs font-weight-bolder opacity-6"
            >
              Menu
            </h6>
          </li>
          <li class="nav-item">
            <router-link
              class="nav-link"
              to="/dosen/pengajuan-ph/mahasiswa-bimbingan"
            >
              <div
                class="icon icon-shape icon-sm border-radius-md text-center me-2 d-flex align-items-center justify-content-center"
              >
                <i class="ni ni-single-02 text-primary text-sm opacity-10"></i>
              </div>
              <span class="nav-link-text ms-1">Mahasiswa Bimbingan</span>
            </router-link>
          </li>
          <li class="nav-item">
            <a
              data-bs-toggle="collapse"
              id="collapsed"
              href="javascript:;"
              class="nav-link"
              role="button"
              v-on:click="showDropdownDosenPengajuanSidang()"
            >
              <div
                class="icon icon-shape icon-sm text-center d-flex align-items-center justify-content-center"
              >
                <i class="ni ni-hat-3 text-success text-sm opacity-10"></i>
              </div>
              <span class="nav-link-text ms-1">Sidang Kompre</span>
            </a>
            <div class="collapse" id="pengajuanSidangDosen">
              <ul class="nav ms-4" id="list">
                <li class="nav-item">
                  <router-link
                    class="nav-link"
                    to="/dosen/pengajuan-sidang/cek-pengajuan"
                  >
                    <span class="nav-link-text ms-1">Pengajuan Sidang</span>
                  </router-link>
                </li>
                <li class="nav-item">
                  <router-link
                    class="nav-link"
                    to="/dosen/pengajuan-sidang/cek-matriks-bimbingan"
                  >
                    <span class="nav-link-text ms-1">Matriks Bimbingan</span>
                  </router-link>
                </li>
                <li class="nav-item">
                  <router-link
                    class="nav-link"
                    to="/dosen/pengajuan-sidang/cek-lembar-disposisi"
                  >
                    <span class="nav-link-text ms-1">Lembar Disposisi</span>
                  </router-link>
                </li>
                <li class="nav-item">
                  <router-link
                    class="nav-link"
                    to="/dosen/pengajuan-sidang/cek-laporan-sidang"
                  >
                    <span class="nav-link-text ms-1">Dokumen Laporan</span>
                  </router-link>
                </li>
                <li class="nav-item">
                  <router-link
                    class="nav-link"
                    to="/dosen/pengajuan-sidang/cek-korespondensi"
                  >
                    <span class="nav-link-text ms-1"
                      >Dokumen Korespondensi</span
                    >
                  </router-link>
                </li>
                <li class="nav-item">
                  <router-link
                    class="nav-link"
                    to="/dosen/pengajuan-sidang/cek-loa"
                  >
                    <span class="nav-link-text ms-1">Letter Of Acceptance</span>
                  </router-link>
                </li>
                <li class="nav-item">
                  <router-link
                    class="nav-link"
                    to="/dosen/pengajuan-sidang/cek-link-jurnal"
                  >
                    <span class="nav-link-text ms-1">Link Jurnal</span>
                  </router-link>
                </li>
              </ul>
            </div>
          </li>
        </template>
        <template v-else-if="role == 'turnitin'">
          <li class="nav-item">
            <h6
              class="ps-4 ms-2 text-uppercase text-xs font-weight-bolder opacity-6"
            >
              Pengajuan Sidang
            </h6>
          </li>
          <li class="nav-item">
            <router-link
              class="nav-link"
              to="/turnitin/pengajuan-sidang/cek-turnitin"
            >
              <div
                class="icon icon-shape icon-sm border-radius-md text-center me-2 d-flex align-items-center justify-content-center"
              >
                <i class="ni ni-single-02 text-primary text-sm opacity-10"></i>
              </div>
              <span class="nav-link-text ms-1">Turnitin Sidang</span>
            </router-link>
          </li>
        </template>
        <template v-else-if="role == 'korbag'">
          <li class="nav-item">
            <h6
              class="ps-4 ms-2 text-uppercase text-xs font-weight-bolder opacity-6"
            >
              Pengajuan Sidang
            </h6>
          </li>
          <li class="nav-item">
            <router-link
              class="nav-link"
              to="/korbag/pengajuan-sidang/cek-lembar-dosposisi"
            >
              <div
                class="icon icon-shape icon-sm border-radius-md text-center me-2 d-flex align-items-center justify-content-center"
              >
                <i
                  class="ni ni-single-copy-04 text-danger text-sm opacity-10"
                ></i>
              </div>
              <span class="nav-link-text ms-1">Lembar Disposisi</span>
            </router-link>
          </li>
        </template>
        <template v-else-if="role == 'labbahasa'">
          <li class="nav-item">
            <h6
              class="ps-4 ms-2 text-uppercase text-xs font-weight-bolder opacity-6"
            >
              Pengajuan
            </h6>
          </li>
          <li class="nav-item">
            <router-link
              class="nav-link"
              to="/lab-bahasa/pengajuan/cek-pengajuan-toefl"
            >
              <div
                class="icon icon-shape icon-sm border-radius-md text-center me-2 d-flex align-items-center justify-content-center"
              >
                <i class="ni ni-single-02 text-primary text-sm opacity-10"></i>
              </div>
              <span class="nav-link-text ms-1">Penjadwalan TOEFL</span>
            </router-link>
          </li>
          <li class="nav-item mt-4">
            <h6
              class="ps-4 ms-2 text-uppercase text-xs font-weight-bolder opacity-6"
            >
              Pengajuan Sidang
            </h6>
          </li>
          <li class="nav-item">
            <router-link
              class="nav-link"
              to="/lab-bahasa/pengajuan/cek-pengajuan-abstrak-sidang"
            >
              <div
                class="icon icon-shape icon-sm border-radius-md text-center me-2 d-flex align-items-center justify-content-center"
              >
                <i class="ni ni-paper-diploma text-info text-sm opacity-10"></i>
              </div>
              <span class="nav-link-text ms-1">Abstrak</span>
            </router-link>
            <router-link
              class="nav-link"
              to="/lab-bahasa/pengajuan/cek-pengajuan-abstrak"
            >
              <div
                class="icon icon-shape icon-sm border-radius-md text-center me-2 d-flex align-items-center justify-content-center"
              >
                <i class="ni ni-paper-diploma text-dark text-sm opacity-10"></i>
              </div>
              <span class="nav-link-text ms-1">Abstrak Artikel</span>
            </router-link>
          </li>
        </template>
        <template v-else-if="role == 'perpustakaan'">
          <li class="nav-item">
            <h6
              class="ps-4 ms-2 text-uppercase text-xs font-weight-bolder opacity-6"
            >
              Pengajuan
            </h6>
          </li>
          <li class="nav-item">
            <router-link
              class="nav-link"
              to="/perpustakaan/pengajuan/dokumen-laporan"
            >
              <div
                class="icon icon-shape icon-sm border-radius-md text-center me-2 d-flex align-items-center justify-content-center"
              >
                <i
                  class="ni ni-single-copy-04 text-primary text-sm opacity-10"
                ></i>
              </div>
              <span class="nav-link-text ms-1">Dokumen Laporan</span>
            </router-link>
          </li>
          <li class="nav-item">
            <router-link
              class="nav-link"
              to="/perpustakaan/pengajuan/bebas-perpustakaan"
            >
              <div
                class="icon icon-shape icon-sm border-radius-md text-center me-2 d-flex align-items-center justify-content-center"
              >
                <i class="ni ni-badge text-success text-sm opacity-10"></i>
              </div>
              <span class="nav-link-text ms-1">Bebas Perpustakaan</span>
            </router-link>
          </li>
          <li class="nav-item">
            <router-link
              class="nav-link"
              to="/perpustakaan/pengajuan/sumbangan-buku"
            >
              <div
                class="icon icon-shape icon-sm border-radius-md text-center me-2 d-flex align-items-center justify-content-center"
              >
                <i class="ni ni-books text-dark text-sm opacity-10"></i>
              </div>
              <span class="nav-link-text ms-1">Sumbangan Buku</span>
            </router-link>
          </li>
        </template>
        <template v-else-if="role == 'admin'">
          <li class="nav-item">
            <h6
              class="ps-4 ms-2 text-uppercase text-xs font-weight-bolder opacity-6"
            >
              Pengajuan PH
            </h6>
          </li>
          <li class="nav-item">
            <router-link class="nav-link" to="/admin">
              <div
                class="icon icon-shape icon-sm border-radius-md text-center me-2 d-flex align-items-center justify-content-center"
              >
                <i class="ni ni-single-02 text-primary text-sm opacity-10"></i>
              </div>
              <span class="nav-link-text ms-1">Profil</span>
            </router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-link" to="/register">
              <div
                class="icon icon-shape icon-sm border-radius-md text-center me-2 d-flex align-items-center justify-content-center"
              >
                <i class="ni ni-single-02 text-primary text-sm opacity-10"></i>
              </div>
              <span class="nav-link-text ms-1">Register</span>
            </router-link>
          </li>
        </template>
      </ul>
    </div>
    <div class="sidenav-footer mx-3">
      <div class="card card-plain shadow-none" id="sidenavCard">
        <img
          class="w-50 mx-auto"
          src="/assets/img/illustrations/icon-documentation.svg"
          alt="sidebar_illustration"
        />
        <div class="card-body text-center p-3 w-100 pt-0">
          <div class="docs-info">
            <h6 class="mb-0">Butuh bantuan?</h6>
            <p class="text-xs font-weight-bold mb-0">
              Hubungi kami di WhatsApp
            </p>
          </div>
        </div>
      </div>
      <a
        href="https://wa.me/6281220482629"
        target="_blank"
        class="btn btn-dark btn-sm w-100 mb-3"
        >WhatsApp</a
      >
      <a class="btn btn-primary btn-sm mb-0 w-100" @click="logout" type="button"
        >Logout</a
      >
    </div>
  </aside>
</template>
<script>
import axios from "axios";
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters({
      isLoggedIn: "isLoggedIn",
      user: "user",
      role: "role",
      aktivasi: "aktivasi",
    }),
  },
  data() {
    return {
      topik: null,
    };
  },
  created() {
    if (this.isLoggedIn) {
      this.getPh();
    }
  },
  methods: {
    showDropdownDosenPengajuanSidang() {
      const dropDown = document.getElementById("pengajuanSidangDosen");
      const collapsed = document.getElementById("collapsed");

      if (this.isDropdown == true) {
        dropDown.classList.remove("show");
        collapsed.classList.remove("collapsed");

        this.isDropdown = false;
      } else {
        dropDown.classList.add("show");
        collapsed.classList.add("collapsed");

        this.isDropdown = true;
      }
    },
    showDropdownAkademikIjazah() {
      const dropDown = document.getElementById("pengurusanIjazah");
      const collapsed = document.getElementById("collapsed");

      if (this.isDropdown == true) {
        dropDown.classList.remove("show");
        collapsed.classList.remove("collapsed");

        this.isDropdown = false;
      } else {
        dropDown.classList.add("show");
        collapsed.classList.add("collapsed");

        this.isDropdown = true;
      }
    },
    showDropdownAkademikPelasanaanSidang() {
      const dropDown = document.getElementById("pelaksanaanSidang");
      const collapsed = document.getElementById("collapsed");

      if (this.isDropdown == true) {
        dropDown.classList.remove("show");
        collapsed.classList.remove("collapsed");

        this.isDropdown = false;
      } else {
        dropDown.classList.add("show");
        collapsed.classList.add("collapsed");

        this.isDropdown = true;
      }
    },
    showDropdownAkademikSidangKompre() {
      const dropDown = document.getElementById("sidangKompre");
      const collapsed = document.getElementById("collapsed");

      if (this.isDropdown == true) {
        dropDown.classList.remove("show");
        collapsed.classList.remove("collapsed");

        this.isDropdown = false;
      } else {
        dropDown.classList.add("show");
        collapsed.classList.add("collapsed");

        this.isDropdown = true;
      }
    },
    showDropdownAkademikSeminarUP() {
      const dropDown = document.getElementById("seminarUp");
      const collapsed = document.getElementById("collapsed");

      if (this.isDropdown == true) {
        dropDown.classList.remove("show");
        collapsed.classList.remove("collapsed");

        this.isDropdown = false;
      } else {
        dropDown.classList.add("show");
        collapsed.classList.add("collapsed");

        this.isDropdown = true;
      }
    },
    showDropdownAkademikPengajuanPH() {
      const dropDown = document.getElementById("applicationsExamples");
      const collapsed = document.getElementById("collapsed");

      if (this.isDropdown == true) {
        dropDown.classList.remove("show");
        collapsed.classList.remove("collapsed");

        this.isDropdown = false;
      } else {
        dropDown.classList.add("show");
        collapsed.classList.add("collapsed");

        this.isDropdown = true;
      }
    },
    showDropdownKeuanganPengajuanPH() {
      const dropDown = document.getElementById("pengajuanKeuangan");
      const collapsed = document.getElementById("collapsed");

      if (this.isDropdown == true) {
        dropDown.classList.remove("show");
        collapsed.classList.remove("collapsed");

        this.isDropdown = false;
      } else {
        dropDown.classList.add("show");
        collapsed.classList.add("collapsed");

        this.isDropdown = true;
      }
    },
    showDropdownKeuanganPembayaran() {
      const dropDown = document.getElementById("pengajuanKeuanganPembayaran");
      const collapsed = document.getElementById("collapsed");

      if (this.isDropdown == true) {
        dropDown.classList.remove("show");
        collapsed.classList.remove("collapsed");

        this.isDropdown = false;
      } else {
        dropDown.classList.add("show");
        collapsed.classList.add("collapsed");

        this.isDropdown = true;
      }
    },
    showDropdownKeuanganUP() {
      const dropDown = document.getElementById("pengajuanKeuanganUP");
      const collapsed = document.getElementById("collapsed");

      if (this.isDropdown == true) {
        dropDown.classList.remove("show");
        collapsed.classList.remove("collapsed");

        this.isDropdown = false;
      } else {
        dropDown.classList.add("show");
        collapsed.classList.add("collapsed");

        this.isDropdown = true;
      }
    },
    showDropdownKeuanganKompre() {
      const dropDown = document.getElementById("pengajuanKeuanganKompre");
      const collapsed = document.getElementById("collapsed");

      if (this.isDropdown == true) {
        dropDown.classList.remove("show");
        collapsed.classList.remove("collapsed");

        this.isDropdown = false;
      } else {
        dropDown.classList.add("show");
        collapsed.classList.add("collapsed");

        this.isDropdown = true;
      }
    },
    getPh() {
      axios
        .get("sita/pengajuan-ph/get-ph/" + this.user.kode)
        .then((response) => {
          let getData = response.data.data;
          if (getData != null) {
            this.topik = response.data.data.topik_ph;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    logout: function () {
      this.$store.dispatch("logout").then(() => {
        this.$router.push("/");
      });
    },
  },
};
</script>
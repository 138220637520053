import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import { setHeaderToken } from '../utils/auth'
import VueSweetalert2 from 'vue-sweetalert2';
import 'bs-stepper/dist/css/bs-stepper.min.css'
import 'sweetalert2/dist/sweetalert2.min.css';

// axios.defaults.baseURL = 'http://fh-api.domain.test/api/v1/'
axios.defaults.baseURL = 'https://api.hukumunpas.com/api/v1/'

Vue.config.productionTip = false
Vue.use(VueSweetalert2);

axios.interceptors.response.use((response) => {
  return response
}, error => {
  const {
    response : {status,data}
  } = error;

  if(status === 500 && data.message === "Token has expired") {
    store.dispatch("logout")
    router.push('/')
  } 
  return Promise.reject(error);
})

const token = localStorage.getItem('token');

if (token) { 
  setHeaderToken(token) 
}

store.dispatch('get_user', token)
.then(() => {
  new Vue({
    router,
    store,
    render: h => h(App)
  }).$mount('#app')
}).catch((error) => {
  console.error(error);
})

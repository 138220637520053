import axios from 'axios'
import { setHeaderToken } from '../../utils/auth'
import { removeHeaderToken } from '../../utils/auth'

export default {
    state: {
        user: null,
        role: null,
        aktivasi: null,
        isLoggedIn: false,
    },
    mutations: {
        set_user(state, data) {
            state.user = data
            state.role = data.role
            state.aktivasi = data.aktivasi
            state.isLoggedIn = true
        },
        reset_user(state) {
            state.user = null
            state.role = null
            state.aktivasi = null
            state.isLoggedIn = false
        }
    },
    getters: {
        isLoggedIn(state) {
            return state.isLoggedIn
        },
        user(state) {
            return state.user
        },
        role(state) {
            return state.role
        },
        aktivasi(state) {
            return state.aktivasi
        }
    },
    actions: {
        login({
            dispatch,
            commit
        }, data) {
            return new Promise((resolve, reject) => {
                axios.post('login', data)
                    .then(response => {
                        const token = response.data.data.token
                        localStorage.setItem('token', token)
                        setHeaderToken(token)
                        dispatch('get_user')
                        resolve(response)
                    })
                    .catch(error => {
                        commit('reset_user')
                        localStorage.removeItem('token')
                        reject(error)
                    })
            })
        },
        async get_user({
            commit
        }) {
            if (!localStorage.getItem('token')) {
                return
            }
            try {
                let response = await axios.get('user')
                commit('set_user', response.data.data)
            } catch (error) {
                commit('reset_user')
                removeHeaderToken()
                localStorage.removeItem('token')
                return error
            }
        },
        logout({
            commit
        }) {
            return new Promise((resolve) => {
                commit('reset_user')
                localStorage.removeItem('token')
                removeHeaderToken()
                resolve()
            })
        }
    }
}
<template>
  <div>
    <div
      class="min-height-500 bg-primary position-absolute w-100"
      style="
        min-height: 200px;
        background-image: url(https://sita.hukum.unpas.ac.id//assets/img/theme/profile-cover.jpg);
        background-size: cover;
        background-position: center top;
      "
    >
      <span class="mask bg-gradient-primary opacity-9"></span>
    </div>
    <template v-if="isLoggedIn">
      <Sidebar />
    </template>
    <main class="main-content position-relative border-radius-lg">
      <!-- Navbar -->
      <template v-if="isLoggedIn">
        <Navbar />
      </template>
      <!-- End Navbar -->
      <template v-if="!isLoggedIn">
        <div class="container">
          <router-view />
          <Footer />
        </div>
      </template>
      <template v-else>
        <div class="container-fluid">
          <router-view />
        </div>
      </template>
    </main>
  </div>
</template>
<script>
import Sidebar from "@/components/layouts/Sidebar.vue";
import Footer from "@/components/layouts/Footer.vue";
import Navbar from "@/components/layouts/Navbar.vue";
import { mapGetters } from "vuex";

export default {
  components: {
    Sidebar,
    Footer,
    Navbar,
  },
  computed: {
    ...mapGetters({
      isLoggedIn: "isLoggedIn",
      user: "user",
      role: "role",
      aktivasi: "aktivasi",
    }),
  },
};
</script>
import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store/index.js'

Vue.use(VueRouter)

const routes = [{
    path: '/',
    name: 'home',
    component: () => import('@/views/auth/Login.vue'),
    meta: {
      guest: true
    }
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/auth/Login.vue'),
    meta: {
      guest: true
    }
  },
  {
    path: '/status-pengajuan',
    name: 'statuspengajuan',
    component: () => import('../views/mahasiswa/page/StatusPengajuan.vue'),
    meta: {
      guest: true
    }
  },
  {
    path: '/jadwal-seminar',
    name: 'jadwalseminar',
    component: () => import('../views/mahasiswa/page/JadwalSeminar.vue'),
    meta: {
      guest: true
    }
  },
  {
    path: '/jadwal-sidang',
    name: 'jadwalsidang',
    component: () => import('../views/mahasiswa/page/JadwalSidang.vue'),
    meta: {
      guest: true
    }
  },
  // mahasiswa
  {
    path: '/profile',
    name: 'profile',
    component: () => import('@/views/mahasiswa/profile/Profile.vue'),
    meta: {
      auth: true,
      isUser: true,
    },
  },
  {
    path: '/reset-password',
    name: 'resetpassword',
    component: () => import('@/views/auth/Resetpassword.vue'),
    meta: {
      auth: true,
      isUser: true,
    }
  },
  {
    path: '/pengajuan-ph',
    name: 'pengajuanph',
    component: () => import('@/views/mahasiswa/pengajuan/Pengajuan.vue'),
    meta: {
      auth: true,
      isUser: true,
    }
  },
  // seminar
  {
    path: '/pengajuan-seminar',
    name: 'pengajuanSeminar',
    component: () => import('@/views/mahasiswa/seminar/Pengajuan.vue'),
    meta: {
      auth: true,
      isUser: true,
    }
  },
  {
    path: '/seminar-up',
    name: 'seminarUp',
    component: () => import('@/views/mahasiswa/seminar/Seminar.vue'),
    meta: {
      auth: true,
      isUser: true,
    }
  },
  // sidang 
  {
    path: '/pengajuan-sidang',
    name: 'pengajuanSidang',
    component: () => import('@/views/mahasiswa/sidang/Pengajuan.vue'),
    meta: {
      auth: true,
      isUser: true,
    }
  },
  {
    path: '/pengajuan-sidang-artikel',
    name: 'pengajuanSidangArtikel',
    component: () => import('@/views/mahasiswa/sidang/PengajuanSidangArtikel.vue'),
    meta: {
      auth: true,
      isUser: true,
    }
  },
  {
    path: '/pengajuan-sidang-presentasi',
    name: 'pengajuanSidangPresentasi',
    component: () => import('@/views/mahasiswa/sidang/PengajuanSidangPresentasi.vue'),
    meta: {
      auth: true,
      isUser: true,
    }
  },
  {
    path: '/sidang-kompre',
    name: 'sidangKompre',
    component: () => import('@/views/mahasiswa/sidang/Sidang.vue'),
    meta: {
      auth: true,
      isUser: true,
    }
  },
  {
    path: '/sidang-kompre-artikel',
    name: 'sidangKompreArtikel',
    component: () => import('@/views/mahasiswa/sidang/SidangArtikel.vue'),
    meta: {
      auth: true,
      isUser: true,
    }
  },
  {
    path: '/sidang-kompre-presentasi',
    name: 'sidangKomprePresentasi',
    component: () => import('@/views/mahasiswa/sidang/SidangPresentasi.vue'),
    meta: {
      auth: true,
      isUser: true,
    }
  },
  {
    path: '/jadwal-sidang-komprehenshif',
    name: 'jadwalSidangKompre',
    component: () => import('@/views/mahasiswa/sidang/JadwalSidang.vue'),
    meta: {
      auth: true,
      isUser: true,
    }
  },
  {
    path: '/ijazah',
    name: 'pengurusanIjazah',
    component: () => import('@/views/mahasiswa/ijazah/PengurusanIjazah.vue'),
    meta: {
      auth: true,
      isUser: true,
    }
  },
  // admin
  {
    path: '/admin',
    name: 'admin',
    component: () => import('@/views/admin/Admin.vue'),
    meta: {
      auth: true,
      isAdmin: true
    }
  },
  {
    path: '/register',
    name: 'register',
    component: () => import('@/views/admin/Register.vue'),
    meta: {
      auth: true,
      isAdmin: true
    }
  },
  // akademik
  {
    path: '/akademik',
    name: 'akademik',
    component: () => import('@/views/akademik/profile/Akademik.vue'),
    meta: {
      auth: true,
      isAkademik: true
    }
  },
  {
    path: '/akademik/pengajuan-ph/cek-transkrip',
    name: 'akademikCekTranskrip',
    component: () => import('@/views/akademik/pengecekan/CekTranskrip.vue'),
    meta: {
      auth: true,
      isAkademik: true
    }
  },
  {
    path: '/akademik/pengajuan-seminar/cek-transkrip-up',
    name: 'akademikCekTranskripUP',
    component: () => import('@/views/akademik/pengecekan/CekTranskripUP.vue'),
    meta: {
      auth: true,
      isAkademik: true
    }
  },
  {
    path: '/akademik/pengajuan-sidang/cek-transkrip-sidang',
    name: 'akademikCekTranskripSidang',
    component: () => import('@/views/akademik/pengecekan/CekTranskripSidang.vue'),
    meta: {
      auth: true,
      isAkademik: true
    }
  },
  {
    path: '/akademik/pengajuan-sidang/cek-slide-sidang',
    name: 'akademikCekSlideSidang',
    component: () => import('@/views/akademik/pengecekan/CekSlideSidang.vue'),
    meta: {
      auth: true,
      isAkademik: true
    }
  },
  {
    path: '/akademik/pengajuan-sidang/cek-ktp',
    name: 'akademikCekKtpSidang',
    component: () => import('@/views/akademik/pengecekan/CekKtpSidang.vue'),
    meta: {
      auth: true,
      isAkademik: true
    }
  },
  {
    path: '/akademik/pengajuan-sidang/cek-pas-foto',
    name: 'akademikCekPasFotoSidang',
    component: () => import('@/views/akademik/pengecekan/CekPasFotoSidang.vue'),
    meta: {
      auth: true,
      isAkademik: true
    }
  },
  {
    path: '/akademik/pengajuan-sidang/penyerahan-berkas',
    name: 'akademikPenyerahanBerkas',
    component: () => import('@/views/akademik/pengecekan/PenyerahanBerkas.vue'),
    meta: {
      auth: true,
      isAkademik: true
    }
  },
  {
    path: '/akademik/pengajuan-sidang/proses-sidang',
    name: 'akademikProsesSidang',
    component: () => import('@/views/akademik/sidang/Sidang.vue'),
    meta: {
      auth: true,
      isAkademik: true
    }
  },
  {
    path: '/akademik/pengajuan-sidang/nilai-artikel/:id',
    name: 'akademikNilaiArtikel',
    component: () => import('@/views/akademik/sidang/IsiNilaiArtikel.vue'),
    meta: {
      auth: true,
      isAkademik: true
    }
  },
  {
    path: '/akademik/ijazah/penyerahan-foto',
    name: 'akademikPenyerahanFoto',
    component: () => import('@/views/akademik/ijazah/PenyerahanFoto.vue'),
    meta: {
      auth: true,
      isAkademik: true
    }
  },
  {
    path: '/akademik/ijazah/pembuatan-ijazah',
    name: 'akademikPembuatanIjazah',
    component: () => import('@/views/akademik/ijazah/PembuatanIjazah.vue'),
    meta: {
      auth: true,
      isAkademik: true
    }
  },
  {
    path: '/akademik/ijazah/pembuatan-ijazah/:id',
    name: 'akademikPembuatanIjazahId',
    component: () => import('@/views/akademik/ijazah/Ijazah.vue'),
    meta: {
      auth: true,
      isAkademik: true
    }
  },
  // keuangan
  {
    path: '/keuangan',
    name: 'keuangan',
    component: () => import('@/views/keuangan/profile/Keuangan.vue'),
    meta: {
      auth: true,
      isKeuangan: true
    }
  },
  {
    path: '/keuangan/pengajuan-ph/pembayaran',
    name: 'keuanganBayarAbstrak',
    component: () => import('@/views/keuangan/pembayaran/Pembayaran.vue'),
    meta: {
      auth: true,
      isKeuangan: true
    }
  },
  {
    path: '/keuangan/pengajuan-ph/cek-dpp',
    name: 'keuanganCekDpp',
    component: () => import('@/views/keuangan/pengecekan/CekDpp.vue'),
    meta: {
      auth: true,
      isKeuangan: true
    }
  },
  {
    path: '/keuangan/pengajuan-ph/cek-dpps',
    name: 'keuanganCekDpps',
    component: () => import('@/views/keuangan/pengecekan/CekDpps.vue'),
    meta: {
      auth: true,
      isKeuangan: true
    }
  },
  {
    path: '/keuangan/pengajuan-seminar/cek-dpp-up',
    name: 'keuanganCekDppUP',
    component: () => import('@/views/keuangan/pengecekan/CekDppUP.vue'),
    meta: {
      auth: true,
      isKeuangan: true
    }
  },
  {
    path: '/keuangan/pengajuan-seminar/cek-dpps-up',
    name: 'keuanganCekDppsUP',
    component: () => import('@/views/keuangan/pengecekan/CekDppsUP.vue'),
    meta: {
      auth: true,
      isKeuangan: true
    }
  },
  {
    path: '/keuangan/pengajuan-sidang/cek-dpp-sidang',
    name: 'keuanganCekDppSidang',
    component: () => import('@/views/keuangan/pengecekan/CekDppSidang.vue'),
    meta: {
      auth: true,
      isKeuangan: true
    }
  },
  {
    path: '/keuangan/pengajuan-sidang/cek-dpps-sidang',
    name: 'keuanganCekDppsSidang',
    component: () => import('@/views/keuangan/pengecekan/CekDppsSidang.vue'),
    meta: {
      auth: true,
      isKeuangan: true
    }
  },
  {
    path: '/keuangan/pengajuan-sidang/cek-dispensasi-dpp-sidang',
    name: 'keuanganCekDispenDppSidang',
    component: () => import('@/views/keuangan/pengecekan/CekDispenDppSidang.vue'),
    meta: {
      auth: true,
      isKeuangan: true
    }
  },
  {
    path: '/keuangan/pengajuan-sidang/cek-dispensasi-dpps-sidang',
    name: 'keuanganCekDispenDppsSidang',
    component: () => import('@/views/keuangan/pengecekan/CekDispenDppsSidang.vue'),
    meta: {
      auth: true,
      isKeuangan: true
    }
  },
  // staff wd 1
  {
    path: '/staff-wakil-dekan',
    name: 'staffwd1',
    component: () => import('@/views/staffwd1/profile/StaffWd1.vue'),
    meta: {
      auth: true,
      isStaffWd1: true
    }
  },
  {
    path: '/staff-wakil-dekan/pengajuan-ph/cek-pengajuan',
    name: 'staffwd1CekPengajuan',
    component: () => import('@/views/staffwd1/pengecekan/CekPengajuan.vue'),
    meta: {
      auth: true,
      isStaffWd1: true
    }
  },
  {
    path: '/staff-wakil-dekan/pengajuan-ph/alokasi-pembimbing',
    name: 'staffwd1AlokasiPembimbing',
    component: () => import('@/views/staffwd1/pengecekan/AlokasiPembimbing.vue'),
    meta: {
      auth: true,
      isStaffWd1: true
    }
  },
  {
    path: '/staff-wakil-dekan/pengajuan-sidang/cek-lembar-dosposisi',
    name: 'staffwd1CekLembarDisposisi',
    component: () => import('@/views/staffwd1/pengecekan/CekLembarDisposisi.vue'),
    meta: {
      auth: true,
      isStaffWd1: true
    }
  },
  {
    path: '/staff-wakil-dekan/pengajuan-sidang/alokasi-penguji',
    name: 'staffwd1AlokasiPenguji',
    component: () => import('@/views/staffwd1/pengecekan/AlokasiPenguji.vue'),
    meta: {
      auth: true,
      isStaffWd1: true
    }
  },
  // wadek
  {
    path: '/wakil-dekan',
    name: 'wd1',
    component: () => import('@/views/wd1/profile/Wd1.vue'),
    meta: {
      auth: true,
      isWd1: true
    }
  },
  {
    path: '/wakil-dekan/pengajuan-ph/cek-pengajuan',
    name: 'wd1CekPengajuan',
    component: () => import('@/views/wd1/pengecekan/AlokasiPembimbing.vue'),
    meta: {
      auth: true,
      isWd1: true
    }
  },
  {
    path: '/wakil-dekan/pengajuan-sidang/cek-laporan-sidang',
    name: 'wd1CekLaporanSidang',
    component: () => import('@/views/wd1/pengecekan/CekLaporanSidang.vue'),
    meta: {
      auth: true,
      isWd1: true
    }
  },
  {
    path: '/wakil-dekan/pengajuan-sidang/cek-korespondensi',
    name: 'wd1CekKorespondensi',
    component: () => import('@/views/wd1/pengecekan/CekKorespondensi.vue'),
    meta: {
      auth: true,
      isWd1: true
    }
  },
  {
    path: '/wakil-dekan/pengajuan-sidang/cek-loa',
    name: 'wd1CekLoa',
    component: () => import('@/views/wd1/pengecekan/CekLoa.vue'),
    meta: {
      auth: true,
      isWd1: true
    }
  },
  {
    path: '/wakil-dekan/pengajuan-sidang/cek-link-jurnal',
    name: 'wd1CekLinkJurnal',
    component: () => import('@/views/wd1/pengecekan/CekLinkJurnal.vue'),
    meta: {
      auth: true,
      isWd1: true
    }
  },
  {
    path: '/wakil-dekan/pengajuan-sidang/alokasi-penguji',
    name: 'wd1AlokasiPenguji',
    component: () => import('@/views/wd1/pengecekan/AlokasiPenguji.vue'),
    meta: {
      auth: true,
      isWd1: true
    }
  },
  // dosen
  {
    path: '/dosen',
    name: 'dosen',
    component: () => import('@/views/dosen/profile/Dosen.vue'),
    meta: {
      auth: true,
      isDosen: true
    }
  },
  {
    path: '/dosen/pengajuan-ph/mahasiswa-bimbingan',
    name: 'dosenMahasiswaBimbingan',
    component: () => import('@/views/dosen/pengecekan/MahasiswaBimbingan.vue'),
    meta: {
      auth: true,
      isDosen: true
    }
  },
  {
    path: '/dosen/pengajuan-sidang/cek-pengajuan',
    name: 'dosenCekPengajuanSidang',
    component: () => import('@/views/dosen/pengecekan/CekPengajuanSidang.vue'),
    meta: {
      auth: true,
      isDosen: true
    }
  },
  {
    path: '/dosen/pengajuan-sidang/cek-matriks-bimbingan',
    name: 'dosenCekMatriksBimbingan',
    component: () => import('@/views/dosen/pengecekan/CekMatriksBimbingan.vue'),
    meta: {
      auth: true,
      isDosen: true
    }
  },
  {
    path: '/dosen/pengajuan-sidang/cek-lembar-disposisi',
    name: 'dosenCekLembarDisposisi',
    component: () => import('@/views/dosen/pengecekan/CekLembarDisposisi.vue'),
    meta: {
      auth: true,
      isDosen: true
    }
  },
  {
    path: '/dosen/pengajuan-sidang/cek-laporan-sidang',
    name: 'dosenCekLaporanSidang',
    component: () => import('@/views/dosen/pengecekan/CekLaporanSidang.vue'),
    meta: {
      auth: true,
      isDosen: true
    }
  },
  {
    path: '/dosen/pengajuan-sidang/cek-korespondensi',
    name: 'dosenCekKorespondensi',
    component: () => import('@/views/dosen/pengecekan/CekKorespondensi.vue'),
    meta: {
      auth: true,
      isDosen: true
    }
  },
  {
    path: '/dosen/pengajuan-sidang/cek-loa',
    name: 'dosenCekLoa',
    component: () => import('@/views/dosen/pengecekan/CekLoa.vue'),
    meta: {
      auth: true,
      isDosen: true
    }
  },
  {
    path: '/dosen/pengajuan-sidang/cek-link-jurnal',
    name: 'dosenCekLinkJurnal',
    component: () => import('@/views/dosen/pengecekan/CekLinkJurnal.vue'),
    meta: {
      auth: true,
      isDosen: true
    }
  },
  // turnitin
  {
    path: '/turnitin/pengajuan-sidang/cek-turnitin',
    name: 'turnitinCekTurnitinSidang',
    component: () => import('@/views/turnitin/pengecekan/CekTurnitinSidang.vue'),
    meta: {
      auth: true,
      isTurnitin: true
    }
  }, 
  {
    path: '/turnitin',
    name: 'turnitin',
    component: () => import('@/views/turnitin/profile/Turnitin.vue'),
    meta: {
      auth: true,
      isTurnitin: true
    }
  },
  // korbag
  {
    path: '/korbag',
    name: 'korbag',
    component: () => import('@/views/korbag/profile/Korbag.vue'),
    meta: {
      auth: true,
      isKorbag: true
    }
  },
  {
    path: '/korbag/pengajuan-sidang/cek-lembar-dosposisi',
    name: 'korbagCekLembarDisposisi',
    component: () => import('@/views/korbag/pengecekan/CekLembarDisposisi.vue'),
    meta: {
      auth: true,
      isKorbag: true
    }
  },
  // lab bahasa
  {
    path: '/lab-bahasa',
    name: 'labbahasa',
    component: () => import('@/views/labbahasa/profile/LabBahasa.vue'),
    meta: {
      auth: true,
      isLabBahasa: true
    }
  },
  {
    path: '/lab-bahasa/pengajuan/cek-pengajuan-toefl',
    name: 'labbahasaCekPengajuanToefl',
    component: () => import('@/views/labbahasa/pengecekan/CekPengajuanToefl.vue'),
    meta: {
      auth: true,
      isLabBahasa: true
    }
  },
  {
    path: '/lab-bahasa/pengajuan/cek-pengajuan-abstrak',
    name: 'labbahasaCekPengajuanAbstrak',
    component: () => import('@/views/labbahasa/pengecekan/CekPengajuanAbstrak.vue'),
    meta: {
      auth: true,
      isLabBahasa: true
    }
  },
  {
    path: '/lab-bahasa/pengajuan/cek-pengajuan-abstrak-sidang',
    name: 'labbahasaCekPengajuanAbstrakSidang',
    component: () => import('@/views/labbahasa/pengecekan/CekPengajuanAbstrakSidang.vue'),
    meta: {
      auth: true,
      isLabBahasa: true
    }
  },
  // perpustakaan
  {
    path: '/perpustakaan',
    name: 'perpustakaan',
    component: () => import('@/views/perpustakaan/profile/Perpustakaan.vue'),
    meta: {
      auth: true,
      isPerpustakaan: true
    }
  },
  {
    path: '/perpustakaan/pengajuan/dokumen-laporan',
    name: 'perpustakaanCekDokumenLaporan',
    component: () => import('@/views/perpustakaan/pengecekan/CekDokumenLaporan.vue'),
    meta: {
      auth: true,
      isPerpustakaan: true
    }
  },
  {
    path: '/perpustakaan/pengajuan/bebas-perpustakaan',
    name: 'perpustakaanCekBebasPerpustakaan',
    component: () => import('@/views/perpustakaan/pengecekan/CekBebasPerpustakaan.vue'),
    meta: {
      auth: true,
      isPerpustakaan: true
    }
  },
  {
    path: '/perpustakaan/pengajuan/sumbangan-buku',
    name: 'perpustakaanSumbanganBuku',
    component: () => import('@/views/perpustakaan/pengecekan/SumbanganBuku.vue'),
    meta: {
      auth: true,
      isPerpustakaan: true
    }
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  linkActiveClass: "active",
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.auth)) {
    let token = localStorage.getItem('token') != null;
    if (!token) {
      next({
        name: 'login',
        query: {
          redirect: to.fullPath
        }
      })
    } else {
      let roles = store.getters.role
      if (to.matched.some(record => record.meta.isAdmin)) {
        if (roles == "admin") {
          next()
        } else if (roles == "mahasiswa") {
          next({
            name: 'profile'
          })
        } else if (roles == "akademik1") {
          next({
            name: 'akademik'
          })
        } else if (roles == "keuangan1") {
          next({
            name: 'keuangan'
          })
        } else if (roles == "staffwd1") {
          next({
            name: 'staffwd1'
          })
        } else if (roles == "wd1") {
          next({
            name: 'wd1'
          })
        } else if (roles == "dosen") {
          next({
            name: 'dosen'
          })
        } else if (roles == "turnitin") {
          next({
            name: 'turnitin'
          })
        } else if (roles == "korbag") {
          next({
            name: 'korbag'
          })
        } else if (roles == "labbahasa") {
          next({
            name: 'labbahasa'
          })
        } else if (roles == "perpustakaan") {
          next({
            name: 'perpustakaan'
          })
        } else {
          next({
            name: 'home'
          })
        }
      } else if (to.matched.some(record => record.meta.isUser)) {
        if (roles == "mahasiswa") {
          next()
        } else if (roles == "admin") {
          next({
            name: 'admin'
          })
        } else if (roles == "akademik1") {
          next({
            name: 'akademik'
          })
        } else if (roles == "keuangan1") {
          next({
            name: 'keuangan'
          })
        } else if (roles == "staffwd1") {
          next({
            name: 'staffwd1'
          })
        } else if (roles == "wd1") {
          next({
            name: 'wd1'
          })
        } else if (roles == "dosen") {
          next({
            name: 'dosen'
          })
        } else if (roles == "turnitin") {
          next({
            name: 'turnitin'
          })
        } else if (roles == "korbag") {
          next({
            name: 'korbag'
          })
        } else if (roles == "labbahasa") {
          next({
            name: 'labbahasa'
          })
        }  else if (roles == "perpustakaan") {
          next({
            name: 'perpustakaan'
          })
        } else {
          next({
            name: 'home'
          })
        }
      } else if (to.matched.some(record => record.meta.isAkademik)) {
        if (roles == "akademik1") {
          next()
        } else if (roles == "keuangan1") {
          next({
            name: 'keuangan'
          })
        } else if (roles == "admin") {
          next({
            name: 'admin'
          })
        } else if (roles == "mahasiswa") {
          next({
            name: 'mahasiswa'
          })
        } else if (roles == "staffwd1") {
          next({
            name: 'staffwd1'
          })
        } else if (roles == "wd1") {
          next({
            name: 'wd1'
          })
        }else if (roles == "dosen") {
          next({
            name: 'dosen'
          })
        } else if (roles == "turnitin") {
          next({
            name: 'turnitin'
          })
        } else if (roles == "korbag") {
          next({
            name: 'korbag'
          })
        } else if (roles == "labbahasa") {
          next({
            name: 'labbahasa'
          })
        }  else if (roles == "perpustakaan") {
          next({
            name: 'perpustakaan'
          })
        } else {
          next({
            name: 'home'
          })
        }
      } else if (to.matched.some(record => record.meta.isKeuangan)) {
        if (roles == "keuangan1") {
          next()
        } else if (roles == "akademik1") {
          next({
            name: 'akademik'
          })
        } else if (roles == "admin") {
          next({
            name: 'admin'
          })
        } else if (roles == "mahasiswa") {
          next({
            name: 'mahasiswa'
          })
        } else if (roles == "staffwd1") {
          next({
            name: 'staffwd1'
          })
        } else if (roles == "wd1") {
          next({
            name: 'wd1'
          })
        } else if (roles == "dosen") {
          next({
            name: 'dosen'
          })
        } else if (roles == "turnitin") {
          next({
            name: 'turnitin'
          })
        } else if (roles == "korbag") {
          next({
            name: 'korbag'
          })
        } else if (roles == "labbahasa") {
          next({
            name: 'labbahasa'
          })
        }  else if (roles == "perpustakaan") {
          next({
            name: 'perpustakaan'
          })
        } else {
          next({
            name: 'home'
          })
        }
      } else if (to.matched.some(record => record.meta.isStaffWd1)) {
        if (roles == "staffwd1") {
          next()
        } else if (roles == "keuangan1") {
          next({
            name: 'keuangan'
          })
        } else if (roles == "akademik1") {
          next({
            name: 'akademik'
          })
        } else if (roles == "admin") {
          next({
            name: 'admin'
          })
        } else if (roles == "mahasiswa") {
          next({
            name: 'mahasiswa'
          })
        }  else if (roles == "wd1") {
          next({
            name: 'wd1'
          })
        } else if (roles == "dosen") {
          next({
            name: 'dosen'
          })
        } else if (roles == "turnitin") {
          next({
            name: 'turnitin'
          })
        } else if (roles == "korbag") {
          next({
            name: 'korbag'
          })
        } else if (roles == "labbahasa") {
          next({
            name: 'labbahasa'
          })
        }  else if (roles == "perpustakaan") {
          next({
            name: 'perpustakaan'
          })
        } else {
          next({
            name: 'home'
          })
        }
      } else if (to.matched.some(record => record.meta.isWd1)) {
        if (roles == "wd1") {
          next()
        } else if (roles == "keuangan1") {
          next({
            name: 'keuangan'
          })
        } else if (roles == "akademik1") {
          next({
            name: 'akademik'
          })
        } else if (roles == "admin") {
          next({
            name: 'admin'
          })
        } else if (roles == "mahasiswa") {
          next({
            name: 'mahasiswa'
          })
        } else if (roles == "staffwd1") {
          next({
            name: 'staffwd1'
          })
        } else if (roles == "dosen") {
          next({
            name: 'dosen'
          })
        } else if (roles == "turnitin") {
          next({
            name: 'turnitin'
          })
        } else if (roles == "korbag") {
          next({
            name: 'korbag'
          })
        } else if (roles == "labbahasa") {
          next({
            name: 'labbahasa'
          })
        }  else if (roles == "perpustakaan") {
          next({
            name: 'perpustakaan'
          })
        } else {
          next({
            name: 'home'
          })
        }
      } else if (to.matched.some(record => record.meta.isDosen)) {
        if (roles == "dosen") {
          next()
        } else if (roles == "wd1") {
          next({
            name: 'wd1'
          })
        } else if (roles == "keuangan1") {
          next({
            name: 'keuangan'
          })
        } else if (roles == "akademik1") {
          next({
            name: 'akademik'
          })
        } else if (roles == "admin") {
          next({
            name: 'admin'
          })
        } else if (roles == "mahasiswa") {
          next({
            name: 'mahasiswa'
          })
        } else if (roles == "staffwd1") {
          next({
            name: 'staffwd1'
          })
        } else if (roles == "turnitin") {
          next({
            name: 'turnitin'
          })
        } else if (roles == "korbag") {
          next({
            name: 'korbag'
          })
        } else if (roles == "labbahasa") {
          next({
            name: 'labbahasa'
          })
        }  else if (roles == "perpustakaan") {
          next({
            name: 'perpustakaan'
          })
        } else {
          next({
            name: 'home'
          })
        }
      } else if (to.matched.some(record => record.meta.isTurnitin)) {
        if (roles == "turnitin") {
          next()
        } else if (roles == "wd1") {
          next({
            name: 'wd1'
          })
        } else if (roles == "keuangan1") {
          next({
            name: 'keuangan'
          })
        } else if (roles == "akademik1") {
          next({
            name: 'akademik'
          })
        } else if (roles == "admin") {
          next({
            name: 'admin'
          })
        } else if (roles == "mahasiswa") {
          next({
            name: 'mahasiswa'
          })
        } else if (roles == "staffwd1") {
          next({
            name: 'staffwd1'
          })
        } else if (roles == "dosen") {
          next({
            name: 'dosen'
          })
        } else if (roles == "korbag") {
          next({
            name: 'korbag'
          })
        } else if (roles == "labbahasa") {
          next({
            name: 'labbahasa'
          })
        }  else if (roles == "perpustakaan") {
          next({
            name: 'perpustakaan'
          })
        } else {
          next({
            name: 'home'
          })
        }
      } else if (to.matched.some(record => record.meta.isKorbag)) {
        if (roles == "korbag") {
          next()
        } else if (roles == "wd1") {
          next({
            name: 'wd1'
          })
        } else if (roles == "keuangan1") {
          next({
            name: 'keuangan'
          })
        } else if (roles == "akademik1") {
          next({
            name: 'akademik'
          })
        } else if (roles == "admin") {
          next({
            name: 'admin'
          })
        } else if (roles == "mahasiswa") {
          next({
            name: 'mahasiswa'
          })
        } else if (roles == "staffwd1") {
          next({
            name: 'staffwd1'
          })
        } else if (roles == "dosen") {
          next({
            name: 'dosen'
          })
        } else if (roles == "turnitin") {
          next({
            name: 'turnitin'
          })
        } else if (roles == "labbahasa") {
          next({
            name: 'labbahasa'
          })
        }  else if (roles == "perpustakaan") {
          next({
            name: 'perpustakaan'
          })
        } else {
          next({
            name: 'home'
          })
        }
      } else if (to.matched.some(record => record.meta.isLabBahasa)) {
        if (roles == "labbahasa") {
          next()
        } else if (roles == "wd1") {
          next({
            name: 'wd1'
          })
        } else if (roles == "keuangan1") {
          next({
            name: 'keuangan'
          })
        } else if (roles == "akademik1") {
          next({
            name: 'akademik'
          })
        } else if (roles == "admin") {
          next({
            name: 'admin'
          })
        } else if (roles == "mahasiswa") {
          next({
            name: 'mahasiswa'
          })
        } else if (roles == "staffwd1") {
          next({
            name: 'staffwd1'
          })
        } else if (roles == "dosen") {
          next({
            name: 'dosen'
          })
        } else if (roles == "turnitin") {
          next({
            name: 'turnitin'
          })
        } else if (roles == "korbag") {
          next({
            name: 'korbag'
          })
        }  else if (roles == "perpustakaan") {
          next({
            name: 'perpustakaan'
          })
        } else {
          next({
            name: 'home'
          })
        }
      } else if (to.matched.some(record => record.meta.isPerpustakaan)) {
        if (roles == "perpustakaan") {
          next()
        } else if (roles == "wd1") {
          next({
            name: 'wd1'
          })
        } else if (roles == "keuangan1") {
          next({
            name: 'keuangan'
          })
        } else if (roles == "akademik1") {
          next({
            name: 'akademik'
          })
        } else if (roles == "admin") {
          next({
            name: 'admin'
          })
        } else if (roles == "mahasiswa") {
          next({
            name: 'mahasiswa'
          })
        } else if (roles == "staffwd1") {
          next({
            name: 'staffwd1'
          })
        } else if (roles == "dosen") {
          next({
            name: 'dosen'
          })
        } else if (roles == "turnitin") {
          next({
            name: 'turnitin'
          })
        } else if (roles == "korbag") {
          next({
            name: 'korbag'
          })
        }  else if (roles == "labbahasa") {
          next({
            name: 'labbahasa'
          })
        } else {
          next({
            name: 'home'
          })
        }
      }
    }
  } else {
    next()
  }
})

export default router